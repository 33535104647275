<template>
    <TheLayoutDefault class="layout--with-sidebar">
        <aside class="layout-side">
            <div class="layout-inner">
                <AppSection variant="side-menu">
                    <AppHeader>
                        <h3 v-translate class="title">
                            Ontwikkeling
                        </h3>
                    </AppHeader>

                    <AppInputSelect
                        class="u-margin-bottom"
                        hide-details
                        :options="[]"
                        options-label="label"
                        options-track-by="value"
                    />

                    <AppMenuSide variant="small">
                        <AppMenuSideItem>
                            <AppMenuSideLink
                                :to="{}"
                                active-class="nav-side__link--active"
                            >
                                Rekenen
                            </AppMenuSideLink>
                        </AppMenuSideItem>
                        <AppMenuSideItem>
                            <AppMenuSideLink
                                :to="{}"
                                active-class="nav-side__link--active"
                            >
                                Taal
                            </AppMenuSideLink>
                        </AppMenuSideItem>
                        <AppMenuSideItem>
                            <AppMenuSideLink
                                :to="{}"
                                active-class="nav-side__link--active"
                            >
                                Lezen
                            </AppMenuSideLink>
                        </AppMenuSideItem>
                    </AppMenuSide>
                </AppSection>
            </div>
        </aside>

        <AppLayoutMain layout-variant="background">
            <AppLayoutInner>
                <AppWrapper variant="normal">
                    <AppEducationPlanGroup title="Breuken" />

                    <AppEducationPlanGroup title="Tot 10 tellen" />
                </AppWrapper>
            </AppLayoutInner>
        </AppLayoutMain>
    </TheLayoutDefault>
</template>

<script>
import AppEducationPlanGroup from "@/components/AppEducationPlanGroup";
import AppHeader from "@/components/AppHeader";
import AppInputSelect from "@/components/AppInputSelect";
import AppLayoutInner from "@/components/AppLayoutInner";
import AppLayoutMain from "@/components/AppLayoutMain";
import AppMenuSide from "@/components/AppMenuSide";
import AppMenuSideItem from "@/components/AppMenuSideItem";
import AppMenuSideLink from "@/components/AppMenuSideLink";
import AppSection from "@/components/AppSection";
import AppWrapper from "@/components/AppWrapper";
import TheLayoutDefault from "@/components/TheLayoutDefault";
export default {
    name: "DsEducationPlans",
    components: {
        AppWrapper,
        AppEducationPlanGroup,
        AppLayoutMain,
        AppLayoutInner,
        AppInputSelect,
        AppSection,
        AppHeader,
        AppMenuSideLink,
        AppMenuSideItem,
        AppMenuSide,
        TheLayoutDefault
    }
};
</script>
